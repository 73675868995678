// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { styled } from '@mui/material'

interface Props {
  keyId: string
  image: IGatsbyImageData
}

const Image = styled(GatsbyImage)(({ theme }) => ({
  height: '100vh',
}))

const SnapSectionImage = ({ image }: Props) => {
  return <Image image={image} alt="" />
}

export default memo(SnapSectionImage)
