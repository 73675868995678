// npm
import React, { memo } from 'react'
import ContentComponents from '@molecules/contentComponents/ContentComponents'

type component =
  | Queries.DatoCmsComponentHeading
  | Queries.DatoCmsComponentText
  | Queries.DatoCmsComponentLeadText

interface Props {
  components: component[]
}

const SnapSectionText = ({ components }: Props) => {
  return <ContentComponents components={components} />
}

export default memo(SnapSectionText)
