// npm
import React, { memo } from 'react'
import { graphql } from 'gatsby'
import ReactFullpage from '@fullpage/react-fullpage'
import { Box, GlobalStyles, alpha, useTheme } from '@mui/material'

// components
import Layout from '@organisms/global/Layout'
import SnapSectionHero from '@molecules/snapSections/components/SnapSectionHero'
import SnapSectionImage from '@molecules/snapSections/components/SnapSectionImage'
import SnapSectionText from '@molecules/snapSections/components/SnapSectionText'
import Footer from '@organisms/global/Footer'

interface Props {
  data: Queries.ProjectDetailQuery
}

type Section =
  | Queries.DatoCmsSnapSectionImage
  | Queries.DatoCmsSnapSectionHero
  | Queries.DatoCmsSnapSectionText

const SEL = 'custom-section'
const SECTION_SEL = `.${SEL}`

const SectionWrapper = ({ ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
        height: '100vh',
        overflow: 'hidden',
        scrollBehavior: 'smooth',
      }}
    />
  )
}

const ProjectDetailFullPage = ({ data }: Props) => {
  const d = data.datoCmsProject
  const snapSections = [...d.snapSectionHero, ...d.snapSections]
  const theme = useTheme()
  return (
    <>
      <GlobalStyles
        styles={{
          ['#fp-nav']: {
            [theme.breakpoints.down('sm')]: {
              right: `0 !important`,
            },
            ul: {
              li: {
                width: '22px !important',
                height: '22px !important',
                display: 'flex !important',
                alignItems: 'center !important',
                justifyContent: 'center !important',
                a: {
                  width: '22px !important',
                  height: '22px !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  justifyContent: 'center !important',
                  span: {
                    position: 'relative !important',
                    background: 'white !important',
                    width: '8px !important',
                    height: '8px !important',
                    margin: '0 !important',
                    display: 'block !important',
                    top: '0 !important',
                    left: '0 !important',
                    ['&.fp-sr-only']: {
                      display: 'none !important',
                    },
                    ['&:before']: {
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate3d(-50%,-50%,0)',
                      content: '""',
                      display: 'block',
                      width: '22px',
                      height: '22px',
                      background: 'none',
                      borderRadius: '50%',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: alpha(theme.palette.primary.main, 0),
                      transition: theme.transitions.create(['border-color']),
                    },
                  },

                  ['&.active']: {
                    span: {
                      ['&:before']: {
                        borderColor: alpha(theme.palette.primary.main, 0.8),
                      },
                    },
                  },
                },
              },
            },
          },
        }}
      />
      <Layout
        omitFooter
        metadata={{
          seoTags: d.seoMetaTags,
          customDescription: d.description,
          // extraTitle: seoTags.join(', '),
        }}
        headerProps={{
          sizeVariant: 'normal',
          themeVariant: 'dark',
          forceSolidNav: true,
        }}
      >
        <ReactFullpage
          scrollBar
          navigation
          recordHistory={false}
          scrollOverflow={false}
          sectionSelector={SECTION_SEL}
          credits={{}}
          render={() => (
            <ReactFullpage.Wrapper>
              {snapSections.map((sectionItem: Section, index: number) => {
                switch (sectionItem.model.apiKey) {
                  case 'snap_section_hero': {
                    const section =
                      sectionItem as Queries.DatoCmsSnapSectionHero
                    return (
                      <SectionWrapper
                        key={`snap-section-${section.originalId}`}
                        className={SEL}
                      >
                        <SnapSectionHero
                          keyId={section.originalId}
                          image={section.image.gatsbyImageData}
                          mainHeading={
                            section.mainHeadingNode.childMarkdownRemark.html
                          }
                          subtitle={section.smallHeading}
                        />
                      </SectionWrapper>
                    )
                  }
                  case 'snap_section_image': {
                    const section =
                      sectionItem as Queries.DatoCmsSnapSectionImage
                    return (
                      <SectionWrapper
                        key={`snap-section-${section.originalId}`}
                        className={SEL}
                      >
                        <SnapSectionImage
                          keyId={section.originalId}
                          image={section.image.gatsbyImageData}
                        />
                      </SectionWrapper>
                    )
                  }
                  case 'snap_section_text': {
                    const section =
                      sectionItem as Queries.DatoCmsSnapSectionText
                    return (
                      <SectionWrapper
                        key={`snap-section-${section.originalId}`}
                        className={SEL}
                        bgcolor={theme.palette.primary.light}
                      >
                        <SnapSectionText components={section.textComponents} />
                      </SectionWrapper>
                    )
                  }

                  default: {
                    return <></>
                  }
                }
              })}
              <div className={`${SEL} fp-auto-height`}>
                <Footer />
              </div>
            </ReactFullpage.Wrapper>
          )}
        />
      </Layout>
    </>
  )
}

export default memo(ProjectDetailFullPage)

export const pageQuery = graphql`
  query ProjectDetail($originalId: String!) {
    datoCmsProject(originalId: { eq: $originalId }) {
      originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      snapSectionHero {
        originalId
        model {
          apiKey
        }
        image {
          gatsbyImageData(
            width: 1920
            layout: CONSTRAINED
            imgixParams: {
              ar: "1920:1080"
              fit: "crop"
              crop: "focalpoint"
              sat: -100
              bri: 10
            }
          )
        }
        mainHeadingNode {
          childMarkdownRemark {
            html
          }
        }
        smallHeading
      }
      snapSections {
        ... on DatoCmsSnapSectionImage {
          image {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              imgixParams: { ar: "1920:1080", fit: "crop", crop: "focalpoint" }
            )
          }
          originalId
          model {
            apiKey
          }
        }
        ... on DatoCmsSnapSectionText {
          originalId
          model {
            apiKey
          }
          textComponents {
            ... on DatoCmsComponentLeadText {
              ...componentLeadText
            }
            ... on DatoCmsComponentHeading {
              ...componentHeading
            }
            ... on DatoCmsComponentText {
              ...componentText
            }
          }
        }
      }
    }
  }
`
