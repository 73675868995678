// npm
import React, { memo } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, styled } from '@mui/material'

// components
import SectionText, { SectionTextProps } from '@molecules/text/SectionText'
import { createImageSectionTextDefaults } from 'helpers/createImageSectionTextDefaults'

const BgImage = styled(GatsbyImage)(({ theme }) => ({
  opacity: 0.45,
  height: '100vh',
  position: 'absolute',
  mixBlendMode: 'overlay',
}))

interface Props {
  keyId: string
  image: IGatsbyImageData
  subtitle: string
  mainHeading: string
}

const SnapSectionHero = ({ keyId, image, subtitle, mainHeading }: Props) => {
  const sectionTextVariant = 'centered'

  let sectionTextProps: SectionTextProps = createImageSectionTextDefaults({
    sectionTextVariant,
    keyId,
    mainHeading,
    subtitle,
  })
  sectionTextProps.subtitleProps.color = 'white'
  // @ts-ignore
  sectionTextProps.subtitleProps.borderType = 'bottom'
  // @ts-ignore
  sectionTextProps.mainHeadingProps.typographyProps.variant = 'h1'
  sectionTextProps.mainHeadingProps.typographyProps.color = 'white'

  return (
    <>
      <Box bgcolor="secondary.main" sx={{ height: '100vh' }}>
        <BgImage image={image} alt="" />
        <Container maxWidth="xl" sx={{ height: '100vh', position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
              py: 10,
            }}
          >
            <SectionText {...sectionTextProps} />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default memo(SnapSectionHero)
